<template>
  <v-dialog
    v-model="isVisible"
    content-class="discrepancy-legend"
    width="500px"
    :hide-overlay="true"
    :persistent="true"
    :no-click-animation="true"
    :scrollable="true"
  >
    <v-card
      ref="draggableModal"
      max-height="70%"
      max-width="500px"
      @mousedown="dragStart"
      @mousemove="dragging"
    >
      <v-card-title class="title pl-9 pt-9 darkblue--text">
        <span class="title-text">{{ titlecase(reportKey) }}</span>

        <v-spacer />

        <v-btn
          :ripple="false"
          icon
          @click.stop="close()"
        >
          <v-icon color="brightblue">
            close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text v-if="reportKey === 'pacing'">
        <v-col>
          <v-row class="mb-1">
            <v-col>
              <v-row>
                <v-col>
                  <v-row class="subtitle-2">
                    <v-col class="font-weight-bold pb-0">
                      Revenue Calculations
                    </v-col>
                  </v-row>

                  <v-row class="mt-0">
                    <v-col>
                      <p>
                        At this time, revenue calculations are limited to CPM
                        line items. However, this may be expanded in the
                        future based on
                        <a
                          href="https://forms.gle/L3rmX38g7DYodqPt6"
                          target="_blank"
                        >
                          user feedback
                        </a>.
                      </p>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row class="mt-0">
                <v-col>
                  <v-row>
                    <v-col class="font-weight-bold pb-0">
                      Total Revenue
                    </v-col>
                  </v-row>

                  <v-row class="mt-0">
                    <v-col>
                      <p class="mb-0">
                        Revenue is calculated based on
                        <span class="font-weight-medium">Impression Goal</span> and
                        <span class="font-weight-medium">Rate</span>.
                      </p>
                    </v-col>
                  </v-row>

                  <v-row class="mt-0">
                    <v-col class="overline mx-4 mt-3 py-6 px-9 calculation">
                      (Booked Impressions / 1000) * Rate
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row class="mt-6">
                <v-col>
                  <v-row>
                    <v-col class="font-weight-bold pb-0">
                      Potential Lost Revenue
                    </v-col>
                  </v-row>

                  <v-row class="mt-0">
                    <v-col>
                      <p class="mb-0">
                        Potential Lost Revenue incorporates
                        <span class="font-weight-medium">OSI</span>
                        to determine the revenue that could be lost
                        at the current indicator rate.
                      </p>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col class="overline mx-4 py-6 px-9 calculation">
                      Total Revenue &minus; (Total Revenue * OSI Percent)
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-row class="subtitle-2">
                <v-col class="font-weight-bold">
                  OSI
                </v-col>
              </v-row>

              <v-row class="osi-legend-line pl-1 mt-0">
                <v-col>
                  <v-row class="align-center">
                    <v-col class="indicator-col ml-1">
                      <div class="osi-color-indicator lte-96" />
                    </v-col>
                    <v-col class="pl-1 py-1">
                      &lt; 97
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row class="osi-legend-line pl-1 mt-0">
                <v-col>
                  <v-row class="align-center">
                    <v-col class="indicator-col ml-1">
                      <div class="osi-color-indicator range-97-99" />
                    </v-col>
                    <v-col class="pl-1 py-1">
                      &equals; 97 - 99
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row class="osi-legend-line pl-1 mt-0">
                <v-col>
                  <v-row class="align-center">
                    <v-col class="indicator-col ml-1">
                      <div class="osi-color-indicator range-100-125" />
                    </v-col>
                    <v-col class="pl-1 py-1">
                      &equals; 100 - 125
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row class="osi-legend-line pl-1 mt-0">
                <v-col>
                  <v-row class="align-center">
                    <v-col class="indicator-col ml-1">
                      <div class="osi-color-indicator gte-126" />
                    </v-col>
                    <v-col class="pl-1 py-1">
                      &gt; 125
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-card-text>

      <v-card-text
        v-else-if="reportKey === 'discrepancy'"
        class="px-6 pb-6"
      >
        <v-container grid-list-xs>
          <v-row>
            <v-card-subtitle class="subtitle-2 font-weight-bold pb-3">
              Discrepancy Percentage
            </v-card-subtitle>
          </v-row>

          <v-col class="pl-6">
            <v-row class="discrepancy-legend-line">
              <div class="discrepancy-color-indicator discrepancy-beyond-10" />
              over 10%
            </v-row>

            <v-row class="discrepancy-legend-line">
              <div class="discrepancy-color-indicator discrepancy-between-1-10" />
              1 to 10%
            </v-row>

            <v-row class="discrepancy-legend-line">
              <div class="discrepancy-color-indicator discrepancy-0" />
              0%
            </v-row>

            <v-row class="discrepancy-legend-line">
              <div class="discrepancy-color-indicator discrepancy-between-minus-1-10" />
              -1 to -10%
            </v-row>

            <v-row class="discrepancy-legend-line">
              <div class="discrepancy-color-indicator discrepancy-beyond-minus-10" />
              less than -10%
            </v-row>
          </v-col>
        </v-container>

        <v-container grid-list-xs>
          <v-row>
            <v-col class="subtitle-2 font-weight-bold py-0">
              Calculation
            </v-col>
          </v-row>

          <v-row>
            <v-col class="overline mx-4 mt-3 py-6 px-9 calculation">
              <v-row>
                <span class="dividend">
                  (first party - third party impressions)
                </span>
              </v-row>

              <v-row>
                <span class="divisor">
                  third party impressions
                </span>
              </v-row>
            </v-col>
          </v-row>

          <v-row class="pl-4">
            <v-col>
              <v-row class="body-2 pt-3">
                If the number is ...
              </v-row>

              <v-row>
                <v-list>
                  <v-list-item class="pl-0">
                    <v-list-item-content>
                      <v-list-item-title>
                        Positive
                      </v-list-item-title>

                      <v-list-item-subtitle>
                        1st Party &gt; 3rd Party
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item class="pl-0">
                    <v-list-item-content>
                      <v-list-item-title>
                        Negative
                      </v-list-item-title>

                      <v-list-item-subtitle>
                        1st Party &lt; 3rd Party
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import draggable from 'src/utils/draggable'
import format from 'src/utils/format'

const { mapState } = createNamespacedHelpers('delivery')
const { mapGetters: mapLayoutGetters } = createNamespacedHelpers('layout')
const { mapGetters: mapToolsGetters } = createNamespacedHelpers('tools')

export default {
  name: 'LegendBar',
  data() {
    return { dragger: {} }
  },
  computed: {
    ...mapLayoutGetters(['showLegendBar']),
    ...mapToolsGetters(['reportKey']),
    ...mapState({
      isLegendModalVisible: (state) => state.isLegendModalVisible,
    }),
    isVisible: {
      get() {
        return this.isLegendModalVisible
      },
      set(value) {
        this.$store.commit('delivery/isLegendModalVisible', value)
      },
    },
  },
  created() {
    document.addEventListener('mouseup', this.finishDragging)
  },
  beforeDestroy() {
    document.removeEventListener('mouseup', this.finishDragging)
  },
  methods: {
    ...draggable,
    close() {
      this.isVisible = false
    },
    titlecase(word) {
      return format.titlecase(word)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~src/styles/variables";

.v-card {
  position: fixed;
  left: 35%;
  top: 20%;
}

.v-card__text {
  background-color: white;
}

.calculation {
  background: $color-monochrome-offwhite;
  border-radius: 4px;
}

.dividend {
  border-bottom: 1px solid $color-monochrome-coolgray;
  padding-bottom: 1px;
}

.divisor {
  padding-top: 1px;
}

.discrepancy-legend {
  border-radius: 6px;
  box-shadow: 0 2px 6px 0 rgba(0, 148, 255, 0.21);
  ::v-deep.v-card__text {
    padding: 13px 19px;
  }
}

.discrepancy-legend-line {
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 11px;
  }

  .discrepancy-color-indicator {
    height: 7px;
    width: 7px;
    border-radius: 50px;
    margin-right: 11px;

    &.discrepancy-0 {
      background-color: $color-legend-neutral;
    }

    &.discrepancy-between-1-10 {
      background-color: $color-legend-yellow;
    }

    &.discrepancy-between-minus-1-10 {
      background-color: $color-legend-green;
    }

    &.discrepancy-beyond-10 {
      background-color: $color-legend-red;
    }

    &.discrepancy-beyond-minus-10 {
      background-color: $color-legend-blue;
    }
  }
}

.indicator-col {
  max-width: 12px;
}

.osi-legend-line {
  .osi-color-indicator {
    height: 7px;
    width: 7px;
    border-radius: 50px;

    &.lte-96 {
      background-color: $color-legend-red;
    }

    &.range-97-99 {
      background-color: $color-legend-yellow;
    }

    &.range-100-125 {
      background-color: $color-legend-green;
    }

    &.gte-126 {
      background-color: $color-legend-blue;
    }
  }
}

</style>
